import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalBasic } from './modal/modal.component';
import { ContactUsComponent } from './forms/contact-us/contact-us.component';
import { IndustryTypesComponent } from './sections/industry-types/industry-types.component';
import { FormTabsComponent } from './sections/form-tabs/form-tabs.component';
import { HomeCarouselComponent } from './sections/home-carousel/home-carousel.component';
import { ContactQueriesService } from 'app/services/form/contact-queries.service';
import { VisitorRegistrationService } from 'app/services/form/visitor-registration.service';
import { VisitorRegistrationComponent } from './forms/visitor-registration/visitor-registration.component';
import { ReferralService } from 'app/services/referral/referral.service';
import { VisitorRegistrationDetailComponent } from './forms/visitor-registration-detail/visitor-registration-detail.component';
import { SpaceBookingComponent } from './forms/space-booking/space-booking.component';
import { SpaceBookingService } from 'app/services/form/space-booking.service';
import { AdvInquiryService } from 'app/services/form/adv-inquiry.service';
import { AdvInquiryComponent } from './forms/adv-inquiry/adv-inquiry.component';
import { SubscriptionComponent } from './forms/subscription/subscription.component';
import { SubscriptionService } from 'app/services/form/subscription.service';
import { TravelInfoComponent } from './sections/travel-info/travel-info.component';
import { CountdownComponent } from './countdown/countdown.component';
import { ExhibhitionDateComponent } from './exhibhition-date/exhibhition-date.component';
import { CustomMaterialModule } from 'app/core/custom-material/custom-material.module';
import { AboutUsComponent } from './sections/about-us/about-us.component';

@NgModule({
  imports: [
    CustomMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NouisliderModule,
    RouterModule,
    JwBootstrapSwitchNg2Module,
  ],
  declarations: [
    HomeCarouselComponent,
    AboutUsComponent,
    FormTabsComponent,
    IndustryTypesComponent,
    TravelInfoComponent,
    ContactUsComponent,
    VisitorRegistrationComponent,
    SpaceBookingComponent,
    AdvInquiryComponent,
    SubscriptionComponent,
    VisitorRegistrationDetailComponent,
    BasicelementsComponent,
    CountdownComponent,
    NavigationComponent,
    TypographyComponent,
    NucleoiconsComponent,
    NotificationComponent,
    ExhibhitionDateComponent,
    NgbdModalBasic,
  ],
  exports: [
    HomeCarouselComponent,
    ContactUsComponent,
    VisitorRegistrationComponent,
    AdvInquiryComponent,
    SpaceBookingComponent,
    SubscriptionComponent,
    IndustryTypesComponent,
    TravelInfoComponent,
    FormTabsComponent,
    AboutUsComponent,
    CountdownComponent,
    ExhibhitionDateComponent,
  ],
  providers: [
    ContactQueriesService,
    VisitorRegistrationService,
    SpaceBookingService,
    ReferralService,
    SubscriptionService,
    AdvInquiryService
  ],
})
export class ComponentsModule {}
