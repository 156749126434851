import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { ContactQueries } from 'app/models/forms/contact-queries.model';


const HttpServiceMethods = {
  ContactQueries: 'contactQueries'
};

@Injectable()
export class ContactQueriesService {
  constructor(private httpService: HttpCallService) {}

  public create(contactQueries: ContactQueries) {
    return this.httpService.callService(
      HttpMethodType.POST,
      HttpServiceMethods.ContactQueries,
      contactQueries
    );
  }
}
