import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-exhibhition-date',
    templateUrl: './exhibhition-date.component.html',
    styleUrls: ['./exhibhition-date.component.scss']
})
export class ExhibhitionDateComponent implements OnInit {

   

    constructor() { }

    ngOnInit() {}


}
