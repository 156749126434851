import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ToastService {
  constructor(private toastr: ToastrService) {}

  public toast(message: string, title?: string) {
    this.toastr.success(title || "", message);
  }

  public error(message: string, title?: string) {
    this.toastr.error(title || "", message);
  }
}
