import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { ISubsciptionHttpResponse, Subscription } from 'app/models/forms/subscription.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const HttpServiceMethods = {
  Subscription: 'subscription'
};

@Injectable()
export class SubscriptionService {
  constructor(private httpService: HttpCallService) {}

  public create(subscription: Subscription): Observable<Subscription> {
    return this.httpService.callService(
      HttpMethodType.POST,
      HttpServiceMethods.Subscription,
      subscription
    ).pipe(map((res: any) => Subscription.adapt(res.data)));
  }
}
