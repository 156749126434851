import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastService } from "app/core/services/toast.service";

import {
  ConstantService,
} from "app/services/constants/constant.service";
import { VisitorRegistrationService } from "app/services/form/visitor-registration.service";

import { Observable } from "rxjs";

import { VisitorRegistrationDetail } from "app/models/forms/visitor-registration.model";
@Component({
  selector: "app-visitor-registration-detail",
  templateUrl: "./visitor-registration-detail.component.html",
  styleUrls: ["./visitor-registration-detail.component.scss"],
})
export class VisitorRegistrationDetailComponent implements OnInit {
  public update = false;
  public submitted = false;
  public id: number | undefined;
  public saveForm!: FormGroup;

  public uniqueCode;
  public mobileNumber;

  public regDetails: Observable<VisitorRegistrationDetail> = null;

  ngOnInit() {
    this.uniqueCode = this.route.snapshot.paramMap.get("uniqueCode") || "";
    this.mobileNumber = this.route.snapshot.paramMap.get("mobileNumber") || "";

    console.log("uniqueCode " + this.uniqueCode);
    console.log("mobileNumber " + this.mobileNumber);

    this.regDetails = this.visitorRegistrationService.getDetails(
      this.uniqueCode,
      this.mobileNumber
    );
  }

  ngOnDestroy() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.add("navbar-transparent");
  }

  constructor(
    public constantService: ConstantService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public alert: ToastService,
    public visitorRegistrationService: VisitorRegistrationService
  ) {}

  print(){
    window.print();
  }
}
