import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

export const BASE_URL = environment.baseUrl;

const httpOptions: any = {};

@Injectable({
  providedIn: 'root'
})
export class HttpCallService {
  constructor(private http: HttpClient) {}

  public callService(
    methodType: HttpMethodType,
    method: string,
    bodyJson?: any,
    options?: any
  ) {
    return this.httpCall(BASE_URL, methodType, method, bodyJson, options);
  }

  private httpCall(
    baseUrl: string,
    methodType: HttpMethodType,
    method: string,
    bodyJson?: any,
    options?: any
  ): Observable<any> {
    if (!this.isOnline()) {
      return new Observable((observer) => {
        observer.complete();
      });
    }

    if (!bodyJson) {
      bodyJson = {};
    }

    const serviceUrl = baseUrl + method;

    let reqHttpOptions = httpOptions;
    if (options) {
      reqHttpOptions = { ...httpOptions, ...options };
    }

    if (methodType === HttpMethodType.GET) {
      const params = new URLSearchParams();

      const bodyJsonKeys = Object.keys(bodyJson);
      for (const key of bodyJsonKeys) {
        params.set(key, bodyJson[key]);
      }

      let url = serviceUrl;
      if (bodyJsonKeys.length > 0) {
        url += '?' + params.toString();
      }

      return this.http.get(url, reqHttpOptions);
    } else if (methodType === HttpMethodType.POST) {
      return this.http.post(serviceUrl, bodyJson, reqHttpOptions);
    } else if (methodType === HttpMethodType.PUT) {
      return this.http.put(serviceUrl, bodyJson, reqHttpOptions);
    } else if (methodType === HttpMethodType.DELETE) {
      return this.http.delete(serviceUrl);
    } else if (methodType === HttpMethodType.DELETE_MULTI) {
      httpOptions.body = bodyJson;
      return this.http.delete(serviceUrl, httpOptions);
    }

    return new Observable();
  }

  private isOnline() {
    return navigator.onLine;
  }
}
