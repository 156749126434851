import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';

import { NGXLogger } from 'ngx-logger';

import { GlobalErrorHandler } from './services/globar-error.handler';
import { ToastService } from './services/toast.service';


@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  providers: [
    ToastService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: NGXLogger, useClass: NGXLogger },
    { provide: 'LOCALSTORAGE', useValue: window.localStorage }
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
   
  }
}
