import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { onErrorAlert } from "app/app.constant";
import { ToastService } from "app/core/services/toast.service";
import { Constant } from "app/models/constant/constant.model";
import { DynamicFormField } from "app/models/dynamic-field.modal";

import {
  ConstantNames,
  ConstantService,
} from "app/services/constants/constant.service";

import { Observable } from "rxjs";

import { Router } from "@angular/router";
import { AdvInquiry, AdvInquiryColumns } from "app/models/forms/adv-inquiry.model";
import { AdvInquiryService } from "app/services/form/adv-inquiry.service";
@Component({
  selector: "app-adv-inquiry",
  templateUrl: "./adv-inquiry.component.html",
  styleUrls: ["./adv-inquiry.component.scss"],
})
export class AdvInquiryComponent implements OnInit {
  public update = false;
  public submitted = false;
  public id: number | undefined;
  public saveForm!: FormGroup;
  public advInquiry!: AdvInquiry;

  public industryTypes: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.IndustryTypes);

  public advType: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.AdvertisementTypes);

  columnsSchema: any = AdvInquiryColumns;

  formField: Array<DynamicFormField> = [];

  typeName = "Advertisement Inquiry";

  ngOnInit() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.remove("navbar-transparent");
  }

  ngOnDestroy() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.add("navbar-transparent");
  }

  constructor(
    public constantService: ConstantService,
    public formBuilder: FormBuilder,
    public alert: ToastService,
    public router:Router,
    public advInquiryService: AdvInquiryService
  ) {
    this.advInquiry = AdvInquiry.createBlank();
    this.buildForm();
  }

  buildForm(): void {
    this.saveForm = this.formBuilder.group({});

    if (Array.isArray(this.columnsSchema)) {
      this.columnsSchema.forEach((field: any) => {
        if (field.isFormEditableField) {
          this.formField.push(DynamicFormField.adapt(field));
        }
      });
    }

    this.formField.forEach((element) => {
      this.saveForm.addControl(
        element.id,
        new FormControl("", element.ngValidations)
      );
    });

    this.saveForm.patchValue(this.advInquiry);
  }

  save() {
    this.submitted = true;
    this.saveForm.markAllAsTouched();

    if (!this.saveForm?.invalid) {
      
      this.advInquiry.companyName =
        this.saveForm.get('companyName')?.value || '';
      this.advInquiry.industryTypeId =
        this.saveForm.get('industryTypeId')?.value || 0;
      this.advInquiry.firstName = this.saveForm.get('firstName')?.value || '';
      this.advInquiry.lastName = this.saveForm.get('lastName')?.value || '';
      this.advInquiry.city = this.saveForm.get('city')?.value || '';
      this.advInquiry.state = this.saveForm.get('state')?.value || '';
      this.advInquiry.country = this.saveForm.get('country')?.value || '';
      this.advInquiry.mobileNumber =
        this.saveForm.get('mobileNumber')?.value || '';
      this.advInquiry.phoneNumber =
        this.saveForm.get('phoneNumber')?.value || '';
      this.advInquiry.email = this.saveForm.get('email')?.value || '';
      this.advInquiry.website = this.saveForm.get('website')?.value || '';
      this.advInquiry.advTypeId = 34;

      this.advInquiryService
        .create(this.advInquiry)
        .subscribe({
          next: () => {
            this.alert.toast(
              `Your query was successfully added. We will get back to you shortly.`
            );
            this.router.navigate([
              ``,
            ]);
          },
          error: (e) => onErrorAlert(this.alert, e),
        });
    }
  }
}
