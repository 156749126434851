import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { Constant, IConstantHttpResponse, IConstantDataHttpResponse } from 'app/models/constant/constant.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


const HttpServiceMethods = {
  Constant: "constant",
  GetConstantListByKey: "constant/publicCostantTypeKey",
};

export const enum ConstantNames {
  IndustryTypes = "IndustryTypes",
  UserRolesTypes = "UserRolesTypes",
  VisitorFormTNC = "VisitorFormTNC",
  ApproxSpaceRequired = "ApproxSpaceRequired",
  SubscriptionPlans = "SubscriptionPlans",
  SubscriptionLanguages = "SubscriptionLanguages",
  PublicKeysTableOptions = "PublicKeysTableOptions",
  ContactUsSubjects = "ContactUsSubjects",
  AdvertisementTypes = "AdvertisementTypes",
  ElementTypes = "ElementTypes",
}

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  public constants: { [key: string]: Array<Constant> } = {};

  constructor(private httpService: HttpCallService) {}

  public getConstantList(name: string): Observable<Constant[]> {
    if (this.constants[name]) {
      return of(this.constants[name]);
    } else {
      return this.getConstantListByNameService(name).pipe(
        map((data) => (this.constants[name] = data))
      );
    }
  }

  public getConstantListByNameService(key: string): Observable<Constant[]> {
    return this.httpService
      .callService(
        HttpMethodType.GET,
        HttpServiceMethods.GetConstantListByKey + `/${key}`
      )
      .pipe(
        map((res) =>
          res.data.map((item: IConstantHttpResponse) => Constant.adapt(item))
        )
      );
  }

  public get(id: number): Observable<Constant> {
    return this.httpService
      .callService(HttpMethodType.GET, HttpServiceMethods.Constant + `/${id}`)
      .pipe(
        map((item: IConstantDataHttpResponse) => Constant.adapt(item.data))
      );
  }


}
