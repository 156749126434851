import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-form-tabs",
  templateUrl: "./form-tabs.component.html",
  styleUrls: ["./form-tabs.component.scss"],
})
export class FormTabsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
