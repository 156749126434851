import { ToastService } from "./core/services/toast.service";

export const enum HttpMethodType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  DELETE_MULTI = 'DELETE_MULTI'
}

export const enum STATUS {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  NOT_FOUND = 404,
  UNAUTHORISED = 401,
  NOT_ALLOWED = 405,
  FORBIDDEN = 403,
  ALREADY_EXISTS = 409,
  UNSUPPORTED_MEDIA_TYPE = 415
}

export const Messages = {
  /* Login */
  invalidCredentials: 'Invalid username/password'
};

export const DASHBOARD_URL = 'home';

export const DEFAULT_URL = "home";

export const getEnumKeyByValue = (object: any, value: any): number => {
  return Number(
    Object.keys(object).find(
      (key) => object[key].toLowerCase() === value.toLowerCase()
    )
  );
};

const unknownError = "There is some issue. Please try again.";
export const onErrorAlert = (
  alert: ToastService,
  err?: any,
) => {
  if (typeof err === "string" || err instanceof String) {
    alert.error(err.toString(), undefined);
  } else if (typeof err?.error === "string" || err?.error instanceof String) {
    alert.error(err.error.toString(), undefined);
  } else if (
    typeof err?.errorMessage === "string" ||
    err?.errorMessage instanceof String
  ) {
    alert.error(err.errorMessage.toString(), undefined);
  } else if (err?.error?.title) {
    alert.error(err.error.title, undefined);
  } else if (err?.error?.message) {
    alert.error(err.error.message, undefined);
  } else {
    alert.error(unknownError, undefined);
  }
};

