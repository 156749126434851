import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { VisitorRegistration, VisitorRegistrationDetail } from 'app/models/forms/visitor-registration.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const HttpServiceMethods = {
  VisitorRegistration: 'visitorRegistration',
  NewStore: 'newStore',
  GetDetails: 'visitorRegistration/getDetails',
};

@Injectable()
export class VisitorRegistrationService {
  constructor(private httpService: HttpCallService) {}

  public getDetails(
    uniqueCode: string,
    mobileNumber: string
  ): Observable<VisitorRegistrationDetail> {
    return this.httpService
      .callService(
        HttpMethodType.GET,
        HttpServiceMethods.GetDetails + `/${mobileNumber}` + `/${uniqueCode}`
      )
      .pipe(map((res: any) => VisitorRegistrationDetail.adapt(res)));
  }

  public create(
    visitorRegistration: VisitorRegistration
  ): Observable<VisitorRegistration> {
    return this.httpService
      .callService(
        HttpMethodType.POST,
        HttpServiceMethods.VisitorRegistration,
        visitorRegistration
      )
      .pipe(map((res: any) => VisitorRegistration.adapt(res.data)));
  }
}
