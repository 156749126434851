import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { SpaceBooking, ISpaceBookingHttpResponse } from 'app/models/forms/space-booking.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


const HttpServiceMethods = {
  SpaceBooking: 'spaceBooking'
};

@Injectable()
export class SpaceBookingService {
  constructor(private httpService: HttpCallService) { }

  public create(spaceBooking: SpaceBooking): Observable<SpaceBooking> {
    return this.httpService.callService(
      HttpMethodType.POST,
      HttpServiceMethods.SpaceBooking,
      spaceBooking
    ).pipe(map((res: any) => SpaceBooking.adapt(res.data)));
  }

}
