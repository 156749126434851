export interface ISubsciptionHttpResponse {
  id: number;
  uniqueCode:string;
  companyName: string;
  industryTypeId: number;
  industryTypeName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  designation: string;
  addLine1: string;
  addLine2: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  mobileNumber: string;
  phoneNumber: string;
  email: string;
  website: string;
  subPlanId: number;
  subPlanName: string;
  subLangId: number;
  subLangName: string;
}

export class Subscription {
  constructor(
    public id: number,
    public uniqueCode:string,
    public companyName: string,
    public industryTypeId: number,
    public industryTypeName: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public designation: string,
    public addLine1: string,
    public addLine2: string,
    public pincode: string,
    public city: string,
    public state: string,
    public country: string,
    public mobileNumber: string,
    public phoneNumber: string,
    public email: string,
    public website: string,
    public subPlanId: number,
    public subPlanName: string,
    public subLangId: number,
    public subLangName: string
  ) {}

  public static adapt(data: ISubsciptionHttpResponse) {
    return new this(
      data.id,
      data.uniqueCode,
      data.companyName,
      data.industryTypeId,
      data.industryTypeName,
      data.firstName,
      data.middleName,
      data.lastName,
      data.designation,
      data.addLine1,
      data.addLine2,
      data.pincode,
      data.city,
      data.state,
      data.country,
      data.mobileNumber,
      data.phoneNumber,
      data.email,
      data.website,
      data.subPlanId,
      data.subPlanName,
      data.subLangId,
      data.subLangName
    );
  }

  public static createBlank() {
    return new this(
      0,
      '',
      '',
      34,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'India',
      '',
      '',
      '',
      '',
      21,
      '',
      26,
      ''
    );
  }
}

export const SubscriptionColumns = [
  {
    key: 'id',
    type: 'number',
    title: 'DB ID',
    displayValue: true,
    hideInList: true,
    sortable: true
  },
  {
    key: 'uniqueCode',
    type: 'string',
    title: 'ID',
    displayValue: true,
    sortable: true
  },
  {
    key: 'companyName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Company Name',
    displayValue: true,
    required: false,
    required_error: 'Company Name is Required',
    sortable: true
  },
  {
    key: 'firstName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'First Name',
    displayValue: true,
    required: true,
    required_error: 'First Name is Required',
    sortable: true
  },
  {
    key: 'lastName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Last Name',
    displayValue: true,
    required: true,
    required_error: 'Last Name is Required',
    sortable: true
  },
  {
    key: 'addLine1',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Address',
    displayValue: true,
    required: true,
    required_error: 'Address is Required',
    sortable: true
  },
  {
    key: 'pincode',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Pincode',
    displayValue: true,
    required: true,
    required_error: 'Pincode is Required',
    sortable: true
  },
  {
    key: 'city',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'City',
    displayValue: true,
    required: true,
    required_error: 'City is Required',
    sortable: true
  },
  {
    key: 'state',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'State',
    displayValue: true,
    required: true,
    required_error: 'State is Required',
    sortable: true
  },
  {
    key: 'country',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Country',
    displayValue: true,
    required: true,
    required_error: 'Country is Required',
    sortable: true
  },
  {
    key: 'mobileNumber',
    type: 'phone',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Mobile Number',
    displayValue: true,
    required: true,
    required_error: 'Mobile Number is Required',
    sortable: true
  },
  {
    key: 'phoneNumber',
    type: 'phone',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Phone Number',
    displayValue: true,
    required: false,
    required_error: 'Phone Number is Required',
    sortable: true
  },
  {
    key: 'email',
    type: 'email',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Email',
    displayValue: true,
    required: false,
    required_error: 'Email is Required',
    sortable: true
  },
  {
    key: 'subPlanId',
    compType: 'dropdown',
    type: 'number',
    isFormField: true,
    isFormEditableField: true,
    title: 'Subscription Plan',
    hideInList: true,
    required: true,
    default: 34,
    displayValue: true,
    placeholder: 'Please Select Subscription Plan',
    required_error: 'Subscription Plan is Required',
    sortable: true
  },
  {
    key: 'subPlanName',
    type: 'string',
    title: 'Subscription Plan',
    displayValue: true,
    sortable: true
  },
  {
    key: 'subLangId',
    compType: 'dropdown',
    type: 'number',
    isFormField: true,
    isFormEditableField: true,
    title: 'Subscription Language',
    hideInList: true,
    required: true,
    default: 34,
    displayValue: true,
    placeholder: 'Please Select Subscription Language',
    required_error: 'Subscription Type is Required',
    sortable: true
  },
  {
    key: 'subLangName',
    type: 'string',
    title: 'Subscription Language',
    displayValue: true,
    sortable: true
  }
];
