import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { AdvInquiry, IAdvInquiryHttpResponse } from 'app/models/forms/adv-inquiry.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



const HttpServiceMethods = {
  AdvInquiry: 'advertisementInquiries'
};

@Injectable()
export class AdvInquiryService {
  constructor(private httpService: HttpCallService) { }

  public create(advInquiry: AdvInquiry): Observable<AdvInquiry> {
    return this.httpService.callService(
      HttpMethodType.POST,
      HttpServiceMethods.AdvInquiry,
      advInquiry
    ).pipe(map((res: any) => AdvInquiry.adapt(res.data)));
  }

}
