import * as moment from 'moment';

export interface IReferralHttpResponse {
  id: number;
  uniqueCode: string;
  refCode: string;
  title: string;
  subTitle: string;
  description: string;
  imagePath: string;
  urlPath: string;
  created_at: string;
}

export class Referral {
  constructor(
    public id: number,
    public uniqueCode: string,
    public refCode: string,
    public title: string,
    public subTitle: string,
    public description: string,
    public imagePath: string,
    public urlPath: string,
    public createdAt: string
  ) {}

  public static adapt(data: IReferralHttpResponse) {
    return new this(
      data.id,
      data.uniqueCode,
      data.refCode,
      data.title,
      data.subTitle,
      data.description,
      data.imagePath,
      data.urlPath,
      data.created_at ? moment(data.created_at).format('DD MMM h:mm a') : ''
    );
  }

  public static createBlank() {
    return new this(
      0,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }
}

export const ReferralColumns = [
  {
    key: 'id',
    type: 'number',
    title: 'DB ID',
    displayValue: true,
    hideInList: true,
    sortable: true
  },
  {
    key: 'createdAt',
    type: 'string',
    title: 'Created',
    displayValue: true,
    sortable: true
  },
  {
    key: 'uniqueCode',
    type: 'string',
    title: 'ID',
    displayValue: true,
    sortable: true
  },
  {
    key: 'refCode',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Refferal Code',
    displayValue: true,
    required: true,
    required_error: 'Refferal Code is Required',
    sortable: true
  },
  {
    key: 'title',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    title: 'Title',
    displayValue: true,
    required: true,
    required_error: 'Title is Required',
    sortable: true
  },
  {
    key: 'subTitle',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    title: 'Sub Title',
    displayValue: true,
    required: false,
    required_error: 'Sub Title is Required',
    sortable: true
  },
  {
    key: 'description',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    title: 'Description',
    displayValue: true,
    required: false,
    required_error: 'Description is Required',
    sortable: true
  },
  {
    key: 'imagePath',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    title: 'Image Path',
    displayValue: true,
    required: false,
    required_error: 'Image Path is Required',
    sortable: true
  },
  {
    key: 'urlPath',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    title: 'URL Path',
    displayValue: true,
    required: false,
    required_error: 'URL Path is Required',
    sortable: true
  }
];
