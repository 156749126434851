import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { onErrorAlert } from "app/app.constant";
import { ToastService } from "app/core/services/toast.service";
import { Constant } from "app/models/constant/constant.model";
import { DynamicFormField } from "app/models/dynamic-field.modal";

import {
  ConstantNames,
  ConstantService,
} from "app/services/constants/constant.service";

import { Observable } from "rxjs";

import { Router } from "@angular/router";
import { Subscription, SubscriptionColumns } from "app/models/forms/subscription.model";
import { SubscriptionService } from "app/services/form/subscription.service";
@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent implements OnInit {
  public update = false;
  public submitted = false;
  public id: number | undefined;
  public saveForm!: FormGroup;
  public subscription!: Subscription;

  public industryTypes: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.IndustryTypes);

  public subscriptionPlans: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.SubscriptionPlans);
  public subscriptionLanguages: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.SubscriptionLanguages);

  columnsSchema: any = SubscriptionColumns;

  formField: Array<DynamicFormField> = [];

  typeName = "Subscription";

  ngOnInit() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.remove("navbar-transparent");
  }

  ngOnDestroy() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.add("navbar-transparent");
  }

  constructor(
    public constantService: ConstantService,
    public formBuilder: FormBuilder,
    public alert: ToastService,
    public router:Router,
    public subscriptionService: SubscriptionService
  ) {
    this.subscription = Subscription.createBlank();
    this.buildForm();
  }

  buildForm(): void {
    this.saveForm = this.formBuilder.group({});

    if (Array.isArray(this.columnsSchema)) {
      this.columnsSchema.forEach((field: any) => {
        if (field.isFormEditableField) {
          this.formField.push(DynamicFormField.adapt(field));
        }
      });
    }

    this.formField.forEach((element) => {
      this.saveForm.addControl(
        element.id,
        new FormControl("", element.ngValidations)
      );
    });

    this.saveForm.patchValue(this.subscription);
  }

  save() {
    this.submitted = true;
    this.saveForm.markAllAsTouched();

    if (!this.saveForm?.invalid) {
      
      this.subscription.companyName =
        this.saveForm.get('companyName')?.value || '';
      this.subscription.industryTypeId = 34;
      this.subscription.firstName = this.saveForm.get('firstName')?.value || '';
      this.subscription.lastName = this.saveForm.get('lastName')?.value || '';
      this.subscription.addLine1 = this.saveForm.get('addLine1')?.value || '';
      this.subscription.pincode = this.saveForm.get('pincode')?.value || '';
      this.subscription.city = this.saveForm.get('city')?.value || '';
      this.subscription.state = this.saveForm.get('state')?.value || '';
      this.subscription.country = this.saveForm.get('country')?.value || '';
      this.subscription.mobileNumber =
        this.saveForm.get('mobileNumber')?.value || '';
      this.subscription.phoneNumber =
        this.saveForm.get('phoneNumber')?.value || '';
      this.subscription.email = this.saveForm.get('email')?.value || '';
      this.subscription.subPlanId = this.saveForm.get('subPlanId')?.value || 0;
      this.subscription.subLangId = this.saveForm.get('subLangId')?.value || 0;

      this.subscriptionService
        .create(this.subscription)
        .subscribe({
          next: () => {
            this.alert.toast(
              `Your subscription was successfully added. We will get back to you shortly.`
            );
            this.router.navigate([
              ``,
            ]);
          },
          error: (e) => onErrorAlert(this.alert, e),
        });
    }
  }
}
