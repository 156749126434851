import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { onErrorAlert } from "app/app.constant";
import { ToastService } from "app/core/services/toast.service";
import { Constant } from "app/models/constant/constant.model";
import { DynamicFormField } from "app/models/dynamic-field.modal";
import {
  ContactQueries,
  ContactQueriesColumns,
} from "app/models/forms/contact-queries.model";
import {
  ConstantNames,
  ConstantService,
} from "app/services/constants/constant.service";
import { ContactQueriesService } from "app/services/form/contact-queries.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  public update = false;
  public submitted = false;
  public id: number | undefined;
  public saveForm!: FormGroup;
  public contactqueries!: ContactQueries;

  public contactUsSubjects: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.ContactUsSubjects);

  columnsSchema: any = ContactQueriesColumns;

  formField: Array<DynamicFormField> = [];

  typeName = "Contact Us";

  ngOnInit() { }

  constructor(
    public constantService: ConstantService,
    public formBuilder: FormBuilder,
    public alert: ToastService,
    public contactQueriesService: ContactQueriesService
  ) {
    this.contactqueries = ContactQueries.createBlank();
    this.buildForm();
  }

  buildForm(): void {
    this.saveForm = this.formBuilder.group({});

    if (Array.isArray(this.columnsSchema)) {
      this.columnsSchema.forEach((field: any) => {
        if (field.isFormEditableField) {
          this.formField.push(DynamicFormField.adapt(field));
        }
      });
    }

    this.formField.forEach((element) => {
      this.saveForm.addControl(
        element.id,
        new FormControl("", element.ngValidations)
      );
    });


    this.saveForm.patchValue(this.contactqueries);

  }

  save() {
    this.submitted = true;
    this.saveForm.markAllAsTouched();

    if (!this.saveForm?.invalid) {
      this.contactqueries.firstName =
        this.saveForm.get("firstName")?.value || "";
      this.contactqueries.middleName =
        this.saveForm.get("middleName")?.value || "";
      this.contactqueries.lastName = this.saveForm.get("lastName")?.value || "";
      this.contactqueries.mobileNumber =
        this.saveForm.get("mobileNumber")?.value || "";
      this.contactqueries.phoneNumber =
        this.saveForm.get("phoneNumber")?.value || "";
      this.contactqueries.email = this.saveForm.get("email")?.value || "";
      this.contactqueries.subjectId =
        this.saveForm.get("subjectId")?.value || 0;
      this.contactqueries.message = this.saveForm.get("message")?.value || "";

      this.contactQueriesService.create(this.contactqueries).subscribe({
        next: () => {
          this.alert.toast(
            `Thank you ${this.contactqueries.firstName} for contacting us. We will get back to you shortly.`
          );
        },
        error: (e) => onErrorAlert(this.alert, e),
      });
    }
  }
}
