import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
// import { AgmCoreModule } from '@agm/core';

import { HomeComponent } from './home/home.component';
import { ComponentsModule } from 'app/components/components.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgbModule,
        ComponentsModule,
        NouisliderModule,
        JwBootstrapSwitchNg2Module,
       /* AgmCoreModule.forRoot({
            apiKey: 'YOUR_KEY_HERE'
        }) */
    ],
    declarations: [
        HomeComponent
    ]
})
export class PagesModule { }
