import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { onErrorAlert } from "app/app.constant";
import { ToastService } from "app/core/services/toast.service";
import { Constant } from "app/models/constant/constant.model";
import { DynamicFormField } from "app/models/dynamic-field.modal";
import {
  VisitorRegistration,
  VisitorRegistrationColumns,
} from "app/models/forms/visitor-registration.model";
import { Referral } from "app/models/referral/referral.model";

import {
  ConstantNames,
  ConstantService,
} from "app/services/constants/constant.service";
import { VisitorRegistrationService } from "app/services/form/visitor-registration.service";
import { ReferralService } from "app/services/referral/referral.service";

import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-visitor-registration",
  templateUrl: "./visitor-registration.component.html",
  styleUrls: ["./visitor-registration.component.scss"],
})
export class VisitorRegistrationComponent implements OnInit {
  public update = false;
  public submitted = false;
  public id: number | undefined;
  public saveForm!: FormGroup;
  public visitorRegistration!: VisitorRegistration;

  public refId;
  public refDetails:Referral = null;

  public industryTypes: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.IndustryTypes);

  public visitorTNC: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.VisitorFormTNC);

  columnsSchema: any = VisitorRegistrationColumns;

  formField: Array<DynamicFormField> = [];

  typeName = "Visitor Registration";

  sub:Subscription = new Subscription();

  ngOnInit() {
    //var rellaxHeader = new Rellax(".rellax-header");
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.remove("navbar-transparent");
    this.refId = this.route.snapshot.paramMap.get("ref") || "";
    const localRefSub = this.referralService.get(this.refId || "default").subscribe((refDetail) => {
      localRefSub.unsubscribe();
      this.refId = refDetail.id;
      this.refDetails = refDetail;
      this.refId = refDetail.id;
    });
    this.sub.add(localRefSub);
  }

  ngOnDestroy() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.add("navbar-transparent");
    if(this.sub){
      this.sub.unsubscribe();
    }
  }


  constructor(
    public constantService: ConstantService,
    public referralService: ReferralService,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public alert: ToastService,
    public visitorRegistrationService: VisitorRegistrationService
  ) {
    this.visitorRegistration = VisitorRegistration.createBlank();
    this.buildForm();
  }

  buildForm(): void {
    this.saveForm = this.formBuilder.group({});

    if (Array.isArray(this.columnsSchema)) {
      this.columnsSchema.forEach((field: any) => {
        if (field.isFormEditableField) {
          this.formField.push(DynamicFormField.adapt(field));
        }
      });
    }

    this.formField.forEach((element) => {
      this.saveForm.addControl(
        element.id,
        new FormControl("", element.ngValidations)
      );
    });

    this.saveForm.patchValue(this.visitorRegistration);
  }

  save() {
    this.submitted = true;
    this.saveForm.markAllAsTouched();

    if (!this.saveForm?.invalid) {
      this.visitorRegistration.companyName =
        this.saveForm.get("companyName")?.value || "";
      this.visitorRegistration.industryTypeId =
        this.saveForm.get("industryTypeId")?.value || 0;
      this.visitorRegistration.firstName =
        this.saveForm.get("firstName")?.value || "";
      this.visitorRegistration.middleName =
        this.saveForm.get("middleName")?.value || "";
      this.visitorRegistration.lastName =
        this.saveForm.get("lastName")?.value || "";
      this.visitorRegistration.designation =
        this.saveForm.get("designation")?.value || "";
      this.visitorRegistration.addLine1 =
        this.saveForm.get("addLine1")?.value || "";
      this.visitorRegistration.addLine2 =
        this.saveForm.get("addLine2")?.value || "";
      this.visitorRegistration.pincode =
        this.saveForm.get("pincode")?.value || "";
      this.visitorRegistration.city = this.saveForm.get("city")?.value || "";
      this.visitorRegistration.state = this.saveForm.get("state")?.value || "";
      this.visitorRegistration.country =
        this.saveForm.get("country")?.value || "";
      this.visitorRegistration.mobileNumber =
        this.saveForm.get("mobileNumber")?.value || "";
      this.visitorRegistration.phoneNumber =
        this.saveForm.get("phoneNumber")?.value || "";
      this.visitorRegistration.email = this.saveForm.get("email")?.value || "";
      this.visitorRegistration.website =
        this.saveForm.get("website")?.value || "";
      this.visitorRegistration.referralId = this.refId;

      this.visitorRegistrationService
        .create(this.visitorRegistration)
        .subscribe({
          next: (visitorRegistration:VisitorRegistration) => {
            this.saveForm.patchValue(VisitorRegistration.createBlank());
            this.alert.toast(
              `The ${this.typeName} was successfully ${
                this.update ? "updated" : "added"
              }.`
            );
            this.router.navigate([
              `/registration/${visitorRegistration.mobileNumber}/${visitorRegistration.uniqueCode}`,
            ]);
          },
          error: (e) => onErrorAlert(this.alert, e),
        });
    }
  }
}
