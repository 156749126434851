import { Validators } from '@angular/forms';

export class DynamicFormField {
  public static adapt(data: any) {
    const validations = [];
    const ngValidations = [];

    if (data?.required && data?.required === true) {
      validations.push({
        name: 'required',
        validator: Validators.required,
        message: data?.required_error || '',
      });
      ngValidations.push(Validators.required);
    }

    if (data?.pattern) {
      validations.push({
        name: 'pattern',
        validator: Validators.pattern(data?.pattern || ''),
        message: data?.pattern_error || '',
      });
      ngValidations.push(Validators.pattern(data?.pattern || ''));
    }

    return new this(
      data?.id || data?.key || '',
      data?.isFormField || false,
      data?.isFormEditableField || false,
      data?.classes || '',
      data?.type || '',
      data?.compType || '',
      data?.pattern || '',
      data?.pattern_error || '',
      data?.placeholder || '',
      data?.required || false,
      data?.sortable || false,
      data?.required_error || '',
      data?.title || '',
      data?.iconUrl || '',
      data?.matIcon || '',
      data?.displayDynamicInput || false,
      validations,
      ngValidations
    );
  }

  constructor(
    public id: string,
    public isFormField: boolean,
    public isFormEditableField: boolean,
    public classes: string,
    public type: string,
    public compType: string,
    public pattern: string,
    public patternError: string,
    public placeholder: string,
    public required: boolean,
    public sortable: boolean,
    public requiredError: string,
    public title: string,
    public iconUrl: string,
    public matIcon: string,
    public displayDynamicInput: boolean,
    public validations: any[],
    public ngValidations: any[]
  ) {}
}
