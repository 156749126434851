import { Component, OnInit } from '@angular/core';
import { Constant } from 'app/models/constant/constant.model';
import { ConstantNames, ConstantService } from 'app/services/constants/constant.service';
import { Observable } from 'rxjs';

@Component({
  selector: "app-industry-types",
  templateUrl: "./industry-types.component.html",
  styleUrls: ["./industry-types.component.scss"],
})
export class IndustryTypesComponent implements OnInit {
  public industryTypes: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.IndustryTypes);

  constructor(public constantService:ConstantService) {}

  ngOnInit() {}
}
