export interface IConstantDataHttpResponse {
  data: IConstantHttpResponse;
  status: boolean;
}
export interface IConstantHttpResponse {
  id: number;
  constantTypeId: number;
  constantTypeKey: string;
  key: string;
  value: string;
  description: string;
}

export class Constant {
  constructor(
    public id: number,
    public constantTypeId: number,
    public constantTypeKey: string,
    public key: string,
    public value: string,
    public description: string
  ) {}

  public static adapt(data: IConstantHttpResponse) {
    return new this(
      data.id,
      data.constantTypeId,
      data.constantTypeKey,
      data.key,
      data.value,
      data.description
    );
  }

  public static adaptFromTypeID(typeID: number) {
    return new this(0, typeID, '', '', '', '');
  }
}

export const ConstantColumns = [
  {
    key: 'id',
    type: 'number',
    title: 'ID',
    displayValue: true,
    sortable: true
  },
  {
    key: 'constantTypeKey',
    type: 'string',
    title: 'Constant Type',
    displayValue: true,
    sortable: true
  },
  {
    key: 'key',
    type: 'text',
    isFormField: true,
    isFormEditableField: true,
    title: 'Key',
    displayValue: true,
    required: true,
    required_error: 'Key is Required',
    sortable: true
  },
  {
    key: 'value',
    type: 'textarea',
    isFormField: true,
    isFormEditableField: true,
    title: 'Value',
    displayValue: true,
    required: true,
    required_error: 'Value is Required',
    sortable: true
  },
  {
    key: 'description',
    type: 'textarea',
    isFormField: true,
    isFormEditableField: true,
    title: 'Description',
    displayValue: true,
    required: false,
    required_error: 'Description is Required',
    sortable: true
  },
];
