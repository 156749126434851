import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { onErrorAlert } from "app/app.constant";
import { ToastService } from "app/core/services/toast.service";
import { Constant } from "app/models/constant/constant.model";
import { DynamicFormField } from "app/models/dynamic-field.modal";


import {
  ConstantNames,
  ConstantService,
} from "app/services/constants/constant.service";

import { Observable } from "rxjs";

import { SpaceBooking, SpaceBookingColumns } from "app/models/forms/space-booking.model";
import { SpaceBookingService } from "app/services/form/space-booking.service";
import { Router } from "@angular/router";
@Component({
  selector: "app-space-booking",
  templateUrl: "./space-booking.component.html",
  styleUrls: ["./space-booking.component.scss"],
})
export class SpaceBookingComponent implements OnInit {
  public update = false;
  public submitted = false;
  public id: number | undefined;
  public saveForm!: FormGroup;
  public spaceBooking!: SpaceBooking;

  public industryTypes: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.IndustryTypes);

  public approxSpaceTypes: Observable<Array<Constant>> =
    this.constantService.getConstantList(ConstantNames.ApproxSpaceRequired);

  columnsSchema: any = SpaceBookingColumns;

  formField: Array<DynamicFormField> = [];

  typeName = "Space Booking";

  ngOnInit() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.remove("navbar-transparent");
  }

  ngOnDestroy() {
    var navbar = document.getElementsByTagName("nav")[0];
    navbar.classList.add("navbar-transparent");
  }

  constructor(
    public constantService: ConstantService,
    public formBuilder: FormBuilder,
    public alert: ToastService,
    public router:Router,
    public spaceBookingService: SpaceBookingService
  ) {
    this.spaceBooking = SpaceBooking.createBlank();
    this.buildForm();
  }

  buildForm(): void {
    this.saveForm = this.formBuilder.group({});

    if (Array.isArray(this.columnsSchema)) {
      this.columnsSchema.forEach((field: any) => {
        if (field.isFormEditableField) {
          this.formField.push(DynamicFormField.adapt(field));
        }
      });
    }

    this.formField.forEach((element) => {
      this.saveForm.addControl(
        element.id,
        new FormControl("", element.ngValidations)
      );
    });

    this.saveForm.patchValue(this.spaceBooking);
  }

  save() {
    this.submitted = true;
    this.saveForm.markAllAsTouched();

    if (!this.saveForm?.invalid) {
      this.spaceBooking.companyName =
        this.saveForm.get('companyName')?.value || '';
      this.spaceBooking.industryTypeId =
        this.saveForm.get('industryTypeId')?.value || 0;
      this.spaceBooking.firstName = this.saveForm.get('firstName')?.value || '';
      this.spaceBooking.lastName = this.saveForm.get('lastName')?.value || '';
      this.spaceBooking.addLine1 = this.saveForm.get('addLine1')?.value || '';
      this.spaceBooking.city = this.saveForm.get('city')?.value || '';
      this.spaceBooking.state = this.saveForm.get('state')?.value || '';
      this.spaceBooking.country = this.saveForm.get('country')?.value || '';
      this.spaceBooking.mobileNumber =
        this.saveForm.get('mobileNumber')?.value || '';
      this.spaceBooking.phoneNumber =
        this.saveForm.get('phoneNumber')?.value || '';
      this.spaceBooking.email = this.saveForm.get('email')?.value || '';
      this.spaceBooking.website = this.saveForm.get('website')?.value || '';
      this.spaceBooking.approxSpaceRequiredId =
        this.saveForm.get('approxSpaceRequiredId')?.value || 0;

      this.spaceBookingService
        .create(this.spaceBooking)
        .subscribe({
          next: () => {
            this.alert.toast(
              `Your query was successfully added. We will get back to you shortly.`
            );
            this.router.navigate([
              ``,
            ]);
          },
          error: (e) => onErrorAlert(this.alert, e),
        });
    }
  }
}
