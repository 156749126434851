export interface ISpaceBookingHttpResponse {
  id: number;
  uniqueCode: string;
  companyName: string;
  industryTypeId: number;
  industryTypeName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  designation: string;
  addLine1: string;
  addLine2: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  mobileNumber: string;
  phoneNumber: string;
  email: string;
  website: string;
  approxSpaceRequiredId: number;
  approxSpaceRequiredName: string;
}

export class SpaceBooking {
  constructor(
    public id: number,
    public uniqueCode:string,
    public companyName: string,
    public industryTypeId: number,
    public industryTypeName: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public designation: string,
    public addLine1: string,
    public addLine2: string,
    public pincode: string,
    public city: string,
    public state: string,
    public country: string,
    public mobileNumber: string,
    public phoneNumber: string,
    public email: string,
    public website: string,
    public approxSpaceRequiredId: number,
    public approxSpaceRequiredName: string
  ) {}

  public static adapt(data: ISpaceBookingHttpResponse) {
    return new this(
      data.id,
      data.uniqueCode,
      data.companyName,
      data.industryTypeId,
      data.industryTypeName,
      data.firstName,
      data.middleName,
      data.lastName,
      data.designation,
      data.addLine1,
      data.addLine2,
      data.pincode,
      data.city,
      data.state,
      data.country || 'India',
      data.mobileNumber,
      data.phoneNumber,
      data.email,
      data.website,
      data.approxSpaceRequiredId,
      data.approxSpaceRequiredName
    );
  }

  public static createBlank() {
    return new this(
      0,
      '',
      '',
      34,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'India',
      '',
      '',
      '',
      '',
      30,
      ''
    );
  }
}

export const SpaceBookingColumns = [
  {
    key: 'id',
    type: 'number',
    title: 'DB ID',
    displayValue: true,
    hideInList: true,
    sortable: true
  },
  {
    key: 'uniqueCode',
    type: 'string',
    title: 'ID',
    displayValue: true,
    sortable: true
  },
  {
    key: 'companyName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Company Name',
    displayValue: true,
    required: true,
    required_error: 'Company Name is Required',
    sortable: true
  },
  {
    key: 'industryTypeId',
    compType: 'dropdown',
    type: 'number',
    isFormField: true,
    isFormEditableField: true,
    title: 'Industry Type',
    hideInList: true,
    required: true,
    displayValue: true,
    default: 34,
    placeholder: 'Please Select Industry Type',
    required_error: 'Industry Type is Required',
    sortable: true
  },
  {
    key: 'industryTypeName',
    type: 'string',
    title: 'Industry Type',
    displayValue: true,
    sortable: true
  },
  {
    key: 'firstName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'First Name',
    displayValue: true,
    required: true,
    required_error: 'First Name is Required',
    sortable: true
  },
  {
    key: 'lastName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Last Name',
    displayValue: true,
    required: true,
    required_error: 'Last Name is Required',
    sortable: true
  },
  {
    key: 'addLine1',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Address',
    displayValue: true,
    required: true,
    required_error: 'Address is Required',
    sortable: true
  },
  {
    key: 'city',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'City',
    displayValue: true,
    required: true,
    required_error: 'City is Required',
    sortable: true
  },
  {
    key: 'state',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'State',
    displayValue: true,
    required: true,
    required_error: 'State is Required',
    sortable: true
  },
  {
    key: 'country',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Country',
    displayValue: true,
    required: true,
    required_error: 'Country is Required',
    sortable: true
  },
  {
    key: 'mobileNumber',
    type: 'phone',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Mobile Number',
    displayValue: true,
    required: true,
    required_error: 'Mobile Number is Required',
    sortable: true
  },
  {
    key: 'phoneNumber',
    type: 'phone',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Phone Number',
    displayValue: true,
    required: false,
    required_error: 'Phone Number is Required',
    sortable: true
  },
  {
    key: 'email',
    type: 'email',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Email',
    displayValue: true,
    required: false,
    required_error: 'Email is Required',
    sortable: true
  },
  {
    key: 'website',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: 'Website',
    displayValue: true,
    required: false,
    required_error: 'Website is Required',
    sortable: true
  },
  {
    key: 'approxSpaceRequiredId',
    compType: 'dropdown',
    type: 'number',
    isFormField: true,
    isFormEditableField: true,
    title: 'Approx Space Required',
    hideInList: true,
    required: true,
    displayValue: true,
    default: 34,
    placeholder: 'Please Select Approx Space Required',
    required_error: 'Approx Space is Required',
    sortable: true
  },
];
