import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ContactUsComponent } from './components/forms/contact-us/contact-us.component';
import { VisitorRegistrationComponent } from './components/forms/visitor-registration/visitor-registration.component';
import { VisitorRegistrationDetailComponent } from './components/forms/visitor-registration-detail/visitor-registration-detail.component';
import { SpaceBookingComponent } from './components/forms/space-booking/space-booking.component';
import { AdvInquiryComponent } from './components/forms/adv-inquiry/adv-inquiry.component';
import { SubscriptionComponent } from './components/forms/subscription/subscription.component';

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  /*
    { path: 'index',                component: ComponentsComponent },
    { path: 'nucleoicons',          component: NucleoiconsComponent },
    { path: 'examples/landing',     component: LandingComponent },
    { path: 'examples/login',       component: LoginComponent },
    { path: 'examples/profile',     component: ProfileComponent },
    */
  { path: "home", component: HomeComponent },
  { path: "contact", component: ContactUsComponent },
  { path: "visitor-registration", component: VisitorRegistrationComponent },
  { path: "visitor-registration/:ref", component: VisitorRegistrationComponent },
  { path: "registration/:ref", component: VisitorRegistrationComponent },
  {
    path: "registration/:mobileNumber/:uniqueCode",
    component: VisitorRegistrationDetailComponent,
  },
  { path: "registration", component: VisitorRegistrationComponent },
  { path: "adv-inquiry", component: AdvInquiryComponent },
  { path: "subscription", component: SubscriptionComponent },
  { path: "space-booking", component: SpaceBookingComponent },

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
