import { Injectable } from '@angular/core';
import { HttpMethodType } from 'app/app.constant';
import { HttpCallService } from 'app/core/services/http-call.service';
import { Referral, IReferralHttpResponse } from 'app/models/referral/referral.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const HttpServiceMethods = {
  Referral: "referral/detail",
};

@Injectable()
export class ReferralService {
  constructor(private httpService: HttpCallService) {}
    
  public get(refCode: string): Observable<Referral> {
    return this.httpService
      .callService(
        HttpMethodType.GET,
        HttpServiceMethods.Referral + `/${refCode}`
      )
      .pipe(map((res: any) => Referral.adapt(res.data)));
  }
}
