import * as moment from 'moment';

export interface IContactQueriesHttpResponse {
  id: number;
  uniqueCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  phoneNumber: string;
  subjectId: number;
  subjectName: string;
  message: string;
  created_at: string;
}

export class ContactQueries {
  constructor(
    public id: number,
    public uniqueCode: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public email: string,
    public mobileNumber: string,
    public phoneNumber: string,
    public subjectId: number,
    public subjectName: string,
    public message: string,
    public createdAt: string
  ) {}

  public static adapt(data: IContactQueriesHttpResponse) {
    return new this(
      data.id,
      data.uniqueCode,
      data.firstName,
      data.middleName,
      data.lastName,
      data.email,
      data.mobileNumber,
      data.phoneNumber,
      data.subjectId,
      data.subjectName,
      data.message,
      moment(data.created_at).format('DD MMM h:mm a')
    );
  }

  public static createBlank() {
    return new this(0, '', '', '', '', '', '', '', 29, '', '', '');
  }
}

export const ContactQueriesColumns = [
  {
    key: 'firstName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'First Name',
    displayValue: true,
    required: true,
    required_error: 'First Name is Required',
    sortable: true
  },
  {
    key: 'lastName',
    type: 'text',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Last Name',
    displayValue: true,
    required: true,
    required_error: 'Last Name is Required',
    sortable: true
  },
  {
    key: 'mobileNumber',
    type: 'phone',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Mobile Number',
    displayValue: true,
    required: true,
    required_error: 'Mobile Number is Required',
    sortable: true
  },
  {
    key: 'email',
    type: 'email',
    compType: 'input',
    isFormField: true,
    isFormEditableField: true,
    title: 'Your Email ID',
    displayValue: true,
    required: false,
    required_error: 'Email is Required',
    sortable: true
  },
  {
    key: 'subjectId',
    compType: 'dropdown',
    type: 'number',
    isFormField: true,
    isFormEditableField: true,
    title: 'Subject',
    displayValue: true,
    hideInList: true,
    required: true,
    default: 29,
    placeholder: 'Please Select Subject',
    required_error: 'Subjectis Required',
    sortable: true
  },
  {
    key: 'message',
    compType: 'textarea',
    type: 'string',
    isFormField: true,
    isFormEditableField: true,
    title: 'Message',
    displayValue: true,
    required: true,
    required_error: 'Message is Required',
    sortable: true
  }
];
