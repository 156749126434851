import * as moment from "moment";
export interface IVisitorRegistrationHttpResponse {
  id: number;
  uniqueCode: string;
  companyName: string;
  industryTypeId: number;
  industryTypeName: string;
  referralId: number;
  referralTitle: string;
  firstName: string;
  middleName: string;
  lastName: string;
  designation: string;
  addLine1: string;
  addLine2: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  mobileNumber: string;
  phoneNumber: string;
  email: string;
  website: string;
  registeredTimestamp: string;
  created_at: string;
}

export class VisitorRegistration {
  constructor(
    public id: number,
    public uniqueCode: string,
    public companyName: string,
    public industryTypeId: number,
    public industryTypeName: string,
    public referralId: number,
    public referralTitle: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public designation: string,
    public addLine1: string,
    public addLine2: string,
    public pincode: string,
    public city: string,
    public state: string,
    public country: string,
    public mobileNumber: string,
    public phoneNumber: string,
    public website: string,
    public email: string,
    public registeredTimestamp: string,
    public createdAt: string
  ) {}

  public static adapt(data: IVisitorRegistrationHttpResponse) {
    return new this(
      data.id,
      data.uniqueCode,
      data.companyName,
      data.industryTypeId,
      data.industryTypeName,
      data.referralId,
      data.referralTitle,
      data.firstName,
      data.middleName,
      data.lastName,
      data.designation,
      data.addLine1,
      data.addLine2,
      data.pincode,
      data.city,
      data.state,
      data.country,
      data.mobileNumber,
      data.phoneNumber,
      data.email,
      data.website,
      data.registeredTimestamp
        ? moment(data.registeredTimestamp).format("DD MMM h:mm a")
        : "",
      moment(data.created_at).format("DD MMM h:mm a")
    );
  }

  public static createBlank() {
    return new this(
      0,
      "",
      "",
      34,
      "",
      1,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "India",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }
}

export class VisitorRegistrationDetail {
  constructor(
    public title: string,
    public subTitle: string,
    public description: string
  ) {}

  public static adapt(data: any) {
    return new this(data.title, data.subTitle, data.description);
  }

  public static createBlank() {
    return new this("", "", "");
  }
}

export const VisitorRegistrationColumns = [
  {
    key: "industryTypeId",
    compType: "dropdown",
    type: "number",
    isFormField: true,
    isFormEditableField: true,
    title: "Industry Type",
    hideInList: true,
    required: true,
    displayValue: true,
    default: 34,
    placeholder: "Please Select Industry Type",
    required_error: "Industry Type is Required",
    sortable: true,
  },
  {
    key: "companyName",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    title: "Company Name",
    hideInList: true,
    displayValue: true,
    required: false,
    required_error: "Company Name is Required",
    sortable: true,
  },
  {
    key: "firstName",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    title: "First Name",
    displayValue: true,
    required: true,
    required_error: "First Name is Required",
    sortable: true,
  },
  {
    key: "lastName",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    title: "Last Name",
    displayValue: true,
    required: true,
    required_error: "Last Name is Required",
    sortable: true,
  },
  {
    key: "pincode",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    title: "Pincode",
    displayValue: true,
    required: false,
    required_error: "Pincode is Required",
    sortable: true,
  },
  {
    key: "addLine1",
    compType: "textarea",
    type: "string",
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: "Address",
    displayValue: true,
    required: true,
    required_error: "Address is Required",
    sortable: true,
  },
  {
    key: "city",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    title: "City",
    displayValue: true,
    required: true,
    required_error: "City is Required",
    sortable: true,
  },
  {
    key: "state",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: "State",
    displayValue: true,
    required: true,
    required_error: "State is Required",
    sortable: true,
  },
  {
    key: "country",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: "Country",
    displayValue: true,
    required: true,
    required_error: "Country is Required",
    sortable: true,
  },
  {
    key: "mobileNumber",
    type: "phone",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    title: "Mobile Number",
    displayValue: true,
    required: true,
    required_error: "Mobile Number is Required",
    sortable: true,
  },
  {
    key: "phoneNumber",
    type: "phone",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: "Phone Number",
    displayValue: true,
    required: false,
    required_error: "Phone Number is Required",
    sortable: true,
  },
  {
    key: "email",
    type: "email",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: "Email",
    displayValue: true,
    required: true,
    required_error: "Email is Required",
    sortable: true,
  },
  {
    key: "website",
    type: "text",
    compType: "input",
    isFormField: true,
    isFormEditableField: true,
    hideInList: true,
    title: "Website",
    displayValue: true,
    required: false,
    required_error: "Website is Required",
    sortable: true,
  },
];
