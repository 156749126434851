import { Component, OnInit } from '@angular/core';
import { ConstantService } from 'app/services/constants/constant.service';

@Component({
  selector: "app-travel-info",
  templateUrl: "./travel-info.component.html",
  styleUrls: ["./travel-info.component.scss"],
})
export class TravelInfoComponent implements OnInit {
  
  constructor(public constantService:ConstantService) {}

  ngOnInit() {}
}
